addEventListener("direct-upload:initialize", event => {
    const { target, detail } = event
    const { id, file } = detail
    let thisSize = "";
    let tempsize = file.size / 1000
    let fileUnit = "KB";
    if(tempsize > 1000){
      tempsize = file.size / 1000;
      fileUnit = "MB";
    }
    if(tempsize > 1000){
        tempsize = file.size / 1000;
        fileUnit = "GM";
    }
    thisSize = Math.round(tempsize) + fileUnit;
    target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
    
  `)
    target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent =  file.name + " " + thisSize;
})

addEventListener("direct-upload:start", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
})